import { Capacitor } from "@capacitor/core";
import InnerHTML from "dangerously-set-html-content";
import { useEffect, useState } from "react";



import { Box } from "@chakra-ui/react";



import { useLocation, useSearchParams } from "@remix-run/react";



import { RenderHotmobDesktop } from "./ads/Desktop";



import DW from "~/components/ads/dw";
import { useGlobalContext } from "~/context/global";
import { useLocale, useRegion } from "~/libs/i18n";
import { countDWTakeover, mwDetailFloatingAds, mwListFloatingAds, mwMainFloatingAds, removeFloatingAd, triggerDWTakeover, triggerMWInterstitial, triggerMWPopover } from "~/utils/hotmob";


const HotmobRenderer = () => {
  const { isDesktop, isMobile } = useGlobalContext();

  const [hotmobId, setHotmobId] = useState<string | null>(null);
  const { pathname, key } = useLocation();
  const [sp] = useSearchParams();
  const locale = useLocale();
  const region = useRegion();
  const disableTakeover = sp.get("from") === "not-found";
  const platform = Capacitor.getPlatform();

  useEffect(() => {
    if (!disableTakeover) {
      isDesktop ? triggerDWTakeover() : triggerMWPopover(platform);
    }
  }, [disableTakeover, isDesktop, platform]);

  useEffect(() => {
    if (!hotmobId) {
      return;
    }

    // Takeover trigger + 1
    if (platform === "web" && isDesktop && hotmobId && !disableTakeover) {
      countDWTakeover();
    }

    // MWInterstitia trigger + 1
    if (!isDesktop && hotmobId && !disableTakeover) {
      triggerMWInterstitial(platform);
    }

    let timer: NodeJS.Timeout;
    if (isMobile && hotmobId) {
      timer = setTimeout(() => {
        if (hotmobId?.includes("4a")) {
          mwListFloatingAds(platform);
        }

        if (hotmobId?.includes("2a")) {
          mwMainFloatingAds(platform);
        }

        if (hotmobId?.includes("3a")) {
          mwDetailFloatingAds(platform);
        }
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [disableTakeover, hotmobId, isDesktop, isMobile, platform]);

  useEffect(() => {
    switch (pathname) {
      case `/${region}/${locale}`:
        setHotmobId(`2a-${key}`);
        break;

      case `/${region}/${locale}/`:
        setHotmobId(`2a-${key}`);
        break;

      case `/${region}/${locale}/event`:
        setHotmobId(`4a-${key}`);
        break;

      case `/${region}/${locale}/latest-event`:
        setHotmobId(`4a-${key}`);
        break;

      case `/${region}/${locale}/hot-event`:
        setHotmobId(`4a-${key}`);
        break;

      case `/${region}/${locale}/editor-choice`:
        setHotmobId(`4a-${key}`);
        break;

      case `/${region}/${locale}/post`:
        setHotmobId(`4a-${key}`);
        break;

      case `/${region}/${locale}/location`:
        setHotmobId(`4a-${key}`);
        break;

      default:
        setHotmobId("");
        break;
    }

    if (
      pathname?.includes(`/${region}/${locale}/event/`) ||
      pathname?.includes(`/${region}/${locale}/post/`)
    ) {
      setHotmobId(`3a-${key}`);
    }

    // isMobile && removeFloatingAd();
  }, [isMobile, key, locale, pathname, region]);

  return (
    <>
      {hotmobId && platform === "web" && isDesktop && (
        <Box w="full">
          <RenderHotmobDesktop
            key={hotmobId}
            overflow={"hidden"}
            maxW={970}
            m={"24px auto"}
          >
            <InnerHTML html={DW[hotmobId?.split("-")[0] as keyof typeof DW]} />
          </RenderHotmobDesktop>
        </Box>
      )}
    </>
  );
};

export default HotmobRenderer;